'use client'
import { Metadata } from 'next'
import NotFoundStdPage from 'storefront-modules/common/components/NotFoundStdPage'
import { metadataFactory } from '#/src/appConfig'

export const metadata: Metadata = metadataFactory({
  title: 'Not found',
  description: 'Page does not exist',
  pathname: '/'
})

const NotFound = () => {
  return <NotFoundStdPage text="Page not found" />
}

export default NotFound
