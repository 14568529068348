import { EmptyMessage, GrandSection } from 'ui'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import { PiLinkBreak } from 'react-icons/pi'

interface NotFoundStdPageProps {
  text: string
}

const NotFoundStdPage: FC<NotFoundStdPageProps> = ({ text }) => {
  return (
    <GrandSection
      alignItems="center"
      display="flex"
      justifyContent="center"
      minH="50vh"
      sectionId="not-found">
      <Icon alignSelf="center" as={PiLinkBreak} h="50px" w="50px" />
      <EmptyMessage fontSize="2xl" {...{ text }} />
    </GrandSection>
  )
}

export default NotFoundStdPage
